import React, { PureComponent } from 'react';


export default class Footer extends PureComponent {

    render() {
        return (
            <div id="footer">
                <div className="copyright">2019 © Booking Online - Phòng Công Nghệ | Đất Xanh Miền Bắc</div>
            </div>
        );
    }
}
