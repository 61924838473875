import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { doLogOut } from "../redux/actions";
import arrow from '../images/down-arrow.png';
import NavigationSearch from './NavigationSearch';

const mapDispatchToProps = dispatch => {
    return {
        doLogOut: () => dispatch(doLogOut())
    }
};
const mapStateToProps = state => {
    return {
        name: state.name,
        username: state.username,
        avatar: state.avatar,
    }
};

class Navigation extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
        };
        this.size = 10;
    }

    render() {
        return (
            <header id="header">
                <nav className="navbar navbar-default navbar-fixed-top menu">
                    <div className="container">

                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <a className="navbar-brand" href="/">
                                <img style={{ maxHeight: "30px", display: "inline" }} src={require('../images/dxmb.png')} alt="logo" />
                                <span className={"text-white"}>Booking Online</span>
                            </a>
                        </div>

                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav navbar-right main-menu">
                                <li className="dropdown">
                                    <Link to="/"><i className="icon ion-home" />&nbsp;Trang chủ</Link>
                                </li>
                                <li className="dropdown visible-xs"><Link to="/tin-noi-bo/tin-tuc"><i className="icon ion-ios-paper" /> Phòng ban</Link></li>
                                <li className="dropdown visible-xs"><Link to="/tin-noi-bo/giao-dich"><i className="icon ion-ios-grid-view" /> Dự án</Link></li>
                                {/* <li className="dropdown">
                                    <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" role="button"
                                       aria-haspopup="true" aria-expanded="false">Tin tức
                                        &nbsp;
                                        <span><img src={arrow}/></span>
                                    </a>
                                    <ul className="dropdown-menu newsfeed-home">
                                        <li><Link to="/tin-noi-bo/tin-tuc">Tin phòng ban</Link></li>
                                        <li><Link to="/tin-noi-bo/giao-dich">Tin dự án</Link></li>
                                    </ul>
                                </li>*/}
                                <li className="dropdown"><Link to="/bao-cao/giao-dich"><i className="fa fa-bar-chart" /> Báo cáo</Link></li>
                                <li className="dropdown"><Link to="/cong-dong"><i className="icon ion-ios-people" /> Cộng đồng</Link></li>
                                {/*<li className="dropdown"><Link to="/ho-so">Hồ sơ</Link></li>*/}
                                <li className="dropdown">
                                    <a onClick={this.props.doLogOut} href="javascript:void(0)"><i className="fa fa-sign-out" /> Đăng xuất</a>
                                </li>
                            </ul>
                            {/*Search Form*/}
                            <NavigationSearch />
                            {/*\End Search Form*/}
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
