import React, { Component, Fragment } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { UnauthRoute } from "react-router-auth";
import loadjs from "loadjs";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { doChangeStyles, doCheckAuth } from "./redux/actions";
// import logo from './logo.svg';
//** todo: Vendor css
import "react-toastify/dist/ReactToastify.min.css";
//** todo: CSS
import "./css/bootstrap.min.css";
import "./css/style-livetream.css";
import "./css/style.css";
import "./css/ionicons.min.css";
import "./css/font-awesome.min.css";
import "./css/emoji.css";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "react-confirm-alert/src/react-confirm-alert.css";
//** todo: Components
import AuthRoute from "./Components/AuthRoute";
import Login from "./Components/Login";
import { NotFound } from "./Components/NotFound";
import Footer from "./Components/Footer";
import { Route as MyRoute } from "./utilities/route";
import LiveStream from "./Components/LiveStream";
import PayHelp from "./Components/PayHelp";
import LuckyDraw from "./Components/LuckyDraw";
import { isAndroid, isIOS } from "react-device-detect";

const mapDispatchToProps = (dispatch) => {
    return {
        doCheckAuth: () => dispatch(doCheckAuth()),
        doChangeStyles: (styles = {}) => dispatch(doChangeStyles(styles)),
    };
};
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isLoggedIn,
        showSidebar: state.showSidebar,
        showSidebarSecondary: state.showSidebarSecondary,
        wrapClass: state.wrapClass,
    };
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        };
    }

    componentDidMount() {
        let id = `${window.location}`.split(
            `https://${window.location.host}/tin-noi-bo/`,
        );
        if (id[1]) {
            if (id[1].length > 0) {
                if (isAndroid) {
                    const url = `dxbo://NewsDetails/${id[1]}`;

                    window.location.replace(url);
                } else if (isIOS) {
                    window.location.replace(`dxbo://NewsDetails/${id[1]}`);

                    // setTimeout(() => {
                    //     window.location.replace(
                    //         "https://apps.apple.com/us/app/id1395964522"
                    //     );
                    // }, 10000);
                }
            }
        }

        // console.log('App!!!!', this.props);
        if (!loadjs.isDefined("INIT")) {
            loadjs(
                [
                    "/app-js/jquery-3.1.1.min.js",
                    "/app-js/jquery.sticky-kit.min.js",
                ],
                "INIT",
                {
                    async: false,
                },
            );
        }
        loadjs.ready("INIT", () => {
            console.log("Init js!!!!!!!!!");
            this.setState({ ready: true });
            if (!loadjs.isDefined("GLOBAL")) {
                loadjs(
                    [
                        "css!https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700,700i",
                        "css!https://fonts.googleapis.com/css?family=Coiny",
                        "/app-js/bootstrap.min.js",
                        "/app-js/jquery.appear.min.js",
                        "/app-js/masonry.pkgd.min.js",
                        "/app-js/jquery.incremental-counter.js",
                        "/app-js/script.js",
                    ],
                    "GLOBAL",
                    {
                        async: true,
                    },
                );
            }
        });
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.props.doCheckAuth();
    }

    render() {
        return (
            <Fragment>
                <ToastContainer
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                {/*Spinner loader*/}
                <div id="spinner-wrapper">
                    <div className="spinner" />
                </div>
                <Router>
                    <Switch>
                        <Route
                            path={"/livestreams" + "/:id"}
                            component={LiveStream}
                        />
                        <Route path={"/tro-giup-ke-toan"} component={PayHelp} />
                        {/* <Route path={'/quay-thuong-su-kien'} component={LuckyDraw} /> */}
                        <UnauthRoute
                            exact
                            path={MyRoute.urls.login}
                            component={() => <Login />}
                            redirectTo={"/"}
                            authenticated={this.props.isAuthenticated}
                        />
                        {this.props.isAuthenticated && (
                            <AuthRoute {...this.props} />
                        )}
                        {this.props.isAuthenticated === false && (
                            <Redirect to={MyRoute.urls.login} />
                        )}
                        <Route component={NotFound} />
                    </Switch>
                </Router>
                {/*BEGIN FOOTER*/}
                <Footer />
                {/*END FOOTER*/}
            </Fragment>
        );
    }
}

// export default (App);
export default connect(mapStateToProps, mapDispatchToProps)(App);
