import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

// import { Provider } from 'react-redux';
import store from './redux';
import Root from './Root';


ReactDOM.render(<Root store={store}/>, document.getElementById('root'));