import { toast } from 'react-toastify';
import Storage from "./storage";
import { doLogOut } from "../redux/actions";
import store from '../redux';

const HOST = process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}/` : 'https://bo.dxmb.vn/';

export const NOTIFICATION_TYPES = {
    Apartment: 0,
    Post: 1,
    Forum: 2,
    Transaction: 3,
    Bill: 4
};
/**
 * API route group
 */
export default class API {

    static PREFIX = 'api/';

    static POST = async (endpoint = '', body = {}, options = { toastSuccess: false, toastError: true, auth: true, withoutHost: false }) => {
        const jwt = options.auth ? Storage.Local.GET(Storage.KEYS.JWT, true) : '';
        // console.log('JWT: ', jwt);
        try {
            const response = await fetch(options.withoutHost ? endpoint : HOST + API.PREFIX + endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'text/json',
                    'Authorization': 'Bearer ' + jwt,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
                method: 'POST',
                body: JSON.stringify(body)
            });
            // console.log('response', response);
            switch (response.status) {
                case 401:
                    if (!toast.isActive('Unauthorized')) toast.error('Phiên đăng nhập hết hạn, hãy đăng nhập lại...', { toastId: 'Unauthorized' });
                    store.dispatch(doLogOut());
                    return false;
                default:
            }
            if (response.ok) {
                const json = await response.json();
                // console.log(json);
                if (json.success) {
                    if (options.toastSuccess) toast.success(json.msg ? json.msg : 'Thành công');
                } else {
                    if (options.toastError) toast.warn(json.msg ? json.msg : 'Thành công');
                }
                return json;
            } else {
                console.log('response: ', await response.text());
                if (options.toastError) toast.warn('Không thành công, hãy thử lại!');
                return false;
            }
        } catch (e) {
            console.log(e);
            if (options.toastError && !toast.isActive('Server Error')) toast.error('Lỗi kết nối!', { toastId: 'Server Error' });
            return false;
        }
    };

    static GET = async (endpoint = '', params = {}, options = { toastSuccess: false, toastError: true, auth: true, withoutHost: false }) => {
        const jwt = options.auth ? Storage.Local.GET(Storage.KEYS.JWT, true) : '';
        // console.log('JWT: ', jwt);
        let url = new URL(options.withoutHost ? endpoint : HOST + API.PREFIX + endpoint);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        // console.log('Fetching via GET:', url);
        try {
            const response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt,
                    'Access-Control-Allow-Origin': '*'
                },
                method: 'GET',
            });
            // console.log('response', response);
            switch (response.status) {
                case 401:
                    toast.error('Phiên đăng nhập hết hạn, hãy đăng nhập lại...');
                    store.dispatch(doLogOut());
                    return false;
                default:
            }
            if (response.ok) {
                const json = await response.json();
                // console.log(json);
                if (json.success) {
                    if (options.toastSuccess) toast.success(json.msg ? json.msg : 'Thành công');
                } else {
                    if (options.toastError) toast.warn(json.msg ? json.msg : 'Thành công');
                }
                return json;
            } else {
                console.log('response: ', response);
                if (options.toastError) toast.warn('Không thành công, hãy thử lại!');
                return false;
            }
        } catch (e) {
            console.log(e);
            if (options.toastError) toast.error('Lỗi kết nối!');
            return false;
        }
    };

    static AUTH = {
        login: 'auth/login'
    };

    static $FORUM = {
        newsFeed: 'forum/news-feed',
        postStatus: 'forum/post-status',
        updateStatus: 'forum/post-status/',
        removeStatus: 'forum/remove-status/',
        react: 'forum/react/',
        sendComment: 'forum/send-comment/',
        listComments: 'forum/comments/'
    };

    static $POST = {
        list: 'post/list',
        tags: 'post/tags',
        show: 'post/show/',
        listComments: 'post/comments/',
        sendComment: 'post/send-comment/',
        sendAnswerComment: 'post/send-answer/',
        listAnswer: 'post/list-answer/',
        massAssign: 'post/mass-assign',
    };

    static $PROJECT = {
        list: 'category/list-main',
        listBuildings: 'category/buildings-by-project'
    };

    static $PRODUCT = {
        listByBuilding: 'product/apartments-by-building',
        show: 'product/show/',
        showByCode: 'product/show-by-code',
    };

    static $NOTIFICATION = {
        list: 'notification/list',
    };

    static $EVENT = {
        listParticipants: 'event/participants/',
        notifyWinner: 'event/winner-congrats/',
    };

    static $REPORT = {
        bill: 'report/bill',
    };

    static $USER = {
        listActive: 'user/active',
        getProfile: 'user/profile-by-account/',
        listDepartment: 'user/departments',
        changeAvatar: 'user/update-avatar'
    };
}