import React, { Component } from 'react';
import "../css/ketoan.css"
export default class PayHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            page: 1,
            loadingMore: false,
        };
        this.size = 25;
    }
    render() {
        return (
            <div className="ketoan">
                <div className="container">
                    <img src={require('../images/banner-thanhtoan.png')} class="img-banner mt-3" />
                    <h3>Các hình thức thanh toán</h3>
                    <div id="accordion">
                        <div className="card mt-3">
                            <a className="card-link" data-toggle="collapse" href="#collapseOne">
                                <div className="d-flex">
                                    <div className="line"></div>
                                    <div className="card-header card-header-tt font-weight-bold">
                                        1. Thanh toán bằng mã QR – Code
                                    </div>
                                    <div className="favicon-down ml-auto">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="collapseOne" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p>Quý khách có thể chuyển tiền đặt mua, đặt chỗ cho sản phẩm của mình mọi lúc, mọi nơi bằng cách quét mã QR - Code thông qua ứng dụng Mobile Banking của các ngân hàng liên kết chỉ bằng 2 bước đơn giản:</p>
                                    <div className="d-flex">
                                        <div className="p-2 b1">Bước 1:</div>
                                        <div className="p-2 flex-grow-1">Mở ứng dụng Mobile Banking trên thiết bị di động, chọn chức năng <b>“QR Pay”</b> để quét mã QR với các ngân hàng chúng tôi đã liên kết:</div>
                                    </div>

                                    <div className="imgqr row">
                                        <div className="col-md-6 col-sm-12 pt-3 pb-3 text-center">
                                            <img src={require('../images/qr1.png')} />
                                        </div>
                                        <div className="col-md-6 col-sm-12 pt-3 pb-3 text-center">
                                            <img src={require('../images/qr2.png')} />
                                        </div>
                                        <div className="col-md-6 col-sm-12 pt-3 pb-3 text-center">
                                            <img src={require('../images/qr3.png')} />
                                        </div>
                                        <div className="col-md-6 col-sm-12 pt-3 pb-3 text-center">
                                            <img src={require('../images/qr4.png')} />
                                        </div>
                                    </div>


                                    <div className="d-flex">
                                        <div className="p-2 b1">Bước 2:</div>
                                        <div className="p-2 flex-grow-1">Điền nội dung chuyển tiền: <b>"Tên KH + chuyển tiền căn hộ/lô đất + mã căn hộ/lô đất + tên dự án” </b>và thanh toán.
                                            Ví dụ: Nguyen Van A chuyen tien can ho B2-912 du an Phuong Dong Green Park</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                <div className="d-flex">
                                    <div className="line"></div>
                                    <div className="card-header card-header-tt font-weight-bold mr-auto">
                                        2.   Ngân hàng trực tuyến/Internet Banking/Tại quầy giao dịch
                                    </div>
                                    <div className="favicon-down ml-auto">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="collapseTwo" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p>Quý khách có thể chuyển tiền đặt mua, đặt chỗ cho sản phẩm của mình mọi lúc, mọi nơi qua dịch vụ Ngân hàng trực tuyến/ Internet Banking/tại quầy giao dịch.</p>
                                    <p>Điền nội dung chuyển tiền: "Tên KH + chuyển tiền căn hộ/lô đất + mã căn hộ/lô đất + tên dự án”.
                                        Ví dụ: Nguyen Van A chuyen tien can ho B2-912 du an Phuong Dong Green Park</p>
                                    <p>Tên tài khoản:  CÔNG TY CỔ PHẦN DỊCH VỤ VÀ ĐỊA ỐC ĐẤT XANH MIỀN BẮC</p>
                                    <p><img src={require('../images/dstaikhoan.png')} class="dstaikhoan" /></p>

                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                <div className="d-flex">
                                    <div className="line"></div>
                                    <div className="card-header card-header-tt font-weight-bold mr-auto">
                                        3.   Tiền mặt
                                    </div>
                                    <div className="favicon-down ml-auto">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p>Ngoài các hình thức trên, Quý khách hàng có thể lựa chọn nộp tiền mặt đặt mua/ đặt chỗ cho các sản phẩm của mình và nhận ngay phiếu xác nhận thu tiền tại:
                                        <b>Phòng kế toán - Công ty CP Dịch vụ và Địa ốc Đất Xanh Miền Bắc
                                            Tầng 18 Center Building, số 1 Nguyễn Huy Tưởng, P. Thanh Xuân Trung, Q. Thanh Xuân, TP. Hà Nội.</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- START Lưu ý --> */}
                    <h3>* Lưu ý</h3>
                    <div className="row m-0">
                        <div className="col-md-6 col-sm-12 luuy1">
                            <img src={require('../images/luuy1.png')} />
                        </div>
                        <div className="col-md-6 col-sm-12 luuy2">
                            <img src={require('../images/luuy2.png')} />
                        </div>
                    </div>
                    {/* <!-- END Lưu ý --> */}

                    {/* <!-- START câu hỏi thường gặp --> */}
                    <h3>Một số câu hỏi thường gặp khi nộp tiền</h3>
                    <div id="accordion">

                        <div className="card mt-3">
                            <a className="card-link" data-toggle="collapse" href="#questionOne">
                                <div className="d-flex">
                                    <div className="card-header card-header-tt mr-auto">
                                        <b>1.</b> Thời gian để Công ty CP Dịch vụ và Địa ốc Đất Xanh Miền Bắc (ĐXMB) xác nhận tiền chuyển là bao lâu?
                                    </div>
                                    <div className="favicon-down">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="questionOne" className="collapse" data-parent="#accordion">

                                <div className="card-body card-body-tt pt-2">
                                    <p>Với những giao dịch chuyển tiền nhanh thành công, khách hàng sẽ nhận được mail hoặc tin nhắn SMS xác nhận đã thu tiền trong vòng 24h ngày làm việc tính từ thời điểm khách hàng chuyển tiền.</p>
                                    <p>Với những giao dịch chuyển tiền thường, khách hàng sẽ nhận được mail hoặc tin nhắn SMS xác nhận đã thu tiền trong vòng 24h ngày làm việc tính từ thời điểm Công ty ĐXMB nhận được tiền.</p>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <a className="collapsed card-link" data-toggle="collapse" href="#questionTwo">
                                <div className="d-flex">
                                    <div className="card-header card-header-tt mr-auto">
                                        <b>2.</b> Nếu KH chuyển tiền thừa, nhầm vào ĐXMB phải làm thế nào?
                                    </div>
                                    <div className="favicon-down">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="questionTwo" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p>Khách hàng vui lòng liên hệ ngay với NVKD khi chuyển tiền thừa, hoặc liên hệ với phòng kế toán Công ty ĐXMB để được hướng dẫn.</p>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <a className="collapsed card-link" data-toggle="collapse" href="#questionThree">
                                <div className="d-flex">
                                    <div className="card-header card-header-tt mr-auto">
                                        <b>3.</b> Nếu tôi ghi sai nội dung chuyển tiền cần làm gì?
                                    </div>
                                    <div className="favicon-down">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="questionThree" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p>Cách 1: Nếu khách hàng chuyển tiền Internet Banking, KH vào Internet banking và làm tra soát nội dụng đúng, đồng thời gửi ảnh chụp tra soát nội dung cho NVKD để kế toán cập nhập nội dung chính xác.</p>
                                    <p>Cách 2: Nếu KH chuyển tiền tại quầy giao dịch, KH vui lòng đến quầy giao dịch tra soát lại nội dung và gửi lại đơn tra soát cho NVKD để kế toán cập nhật lại nội dung chính xác</p>
                                    <p>Nếu khách hàng không tra soát bằng 2 hình thức trên, KH vui lòng làm đơn xác nhận nội dung chính xác và gửi về cho nhân viên kinh doanh hỗ trợ.</p>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <a className="collapsed card-link" data-toggle="collapse" href="#questionFour">
                                <div className="d-flex">
                                    <div className="card-header card-header-tt mr-auto">
                                        <b>4.</b> Không quét được mã QR – Code?
                                    </div>
                                    <div className="favicon-down">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="questionFour" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p>Hiện tại đang có 2 hình thức quét QR – Code : “ QR – Pay “, và “ chuyển 24/7 mã QR “. Quý khách vui lòng chọn hình thức chuyển 24/7 mã QR trên Mobile Banking, để thực hiện giao dịch nhanh chóng.</p>
                                    <img className="img-qr" src={require("../images/question-qr.jpg")} />
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <a className="collapsed card-link" data-toggle="collapse" href="#questionFive">
                                <div className="d-flex">
                                    <div className="card-header card-header-tt mr-auto">
                                        <b>5.</b> Khách hàng chuyển tiền thường chưa nhận được xác nhận của Công ty ĐXMB ?
                                    </div>
                                    <div className="favicon-down">
                                        <img src={require('../images/down.png')} />
                                    </div>
                                </div>
                            </a>
                            <div id="questionFive" className="collapse" data-parent="#accordion">
                                <div className="card-body card-body-tt pt-2">
                                    <p> Nếu sau đó khách hàng vẫn chưa nhận được xác nhận của ĐXMB. Khách hàng vui lòng liên hệ với ngân hàng xác nhận giao dịch thành công.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- END Câu hỏi thường gặp --> */}
                </div>
            </div>
        )
    }
}