import React, { Component } from 'react';
import API from '../utilities/api';

export default class LiveStream extends Component {
    constructor(props) {
        super(props);
        this.id = props.match.params.id;
        this.state = {
            loading: true,
            data: null,
            commentCount: 0
        }
    }
    static propTypes = {
    };

    _fetch = async () => {
        try {
            const json = await API.GET("https://devbov2.dxmb.vn/api/bo/events/list", {
                id: this.id
                // resize: this.resize,
                // size: this.size,
                // page: page,
                // category: category==='tin-tuc'? 0 : (category==='giao-dich'? 1 : null),
                // department: department? department : null,
                // project: project? project : null,
                // priority: null
            }, { withoutHost: true });
            if (json) {
                const data = json.data;
                await this.setState({ data: data[0] });
            }
        } catch (e) {

        }
    }

    componentDidMount() {
        this._fetch()
    }

    render() {
        const { data } = this.state;

        return (
            <div>
                <div className="section full-width">
                    <div className="section_wrapper">

                        <div className="main-left">
                            <div className="ct-left">
                                <div className="header d-flex">
                                    <div className="mr-auto flex-grow-1 d-flex d-header">
                                        <img src={require("../images/livestreams/bg-header.png")} className="img-fluid img-bg"
                                            alt="Responsive image" />
                                        <div className="d-flex ct-d-header">
                                            <div className="logo-tream">
                                                <img src={require("../images/livestreams/logolivetream.png")} />
                                            </div>
                                            <div className="tl-program">
                                                <p className="main-tl">{data ? data.event_title : ""}</p>
                                                {/* <p className="user">MKT-Admin</p> */}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="other ">
                                        <a href=""><img src={require("../images/livestreams/other1.png")} /></a>
                                        <a href=""><img src={require("../images/livestreams/other2.png")} /></a>
                                        <a href=""><img src={require("../images/livestreams/other3.png")} /></a>
                                    </div>
                                </div>

                                <div className="video">
                                    {data &&
                                        <div className="ct-video">
                                            <div dangerouslySetInnerHTML={{
                                                __html: ` <iframe style="pointer-events: none;" id="existing-iframe-example" class='text-right'
                                                width="100%" height="620"
                                                src={${data.youtube_live}?enablejsapi=1&autoplay=1&mute=1&modestbranding=1&autohide=1&rel=0}
                                            frameborder="0" allow="autoplay"
                                            style="border: solid 4px #37474F"
                                            ></iframe>`}}></div>

                                        </div>
                                    }
                                </div>

                                <div className="footer">
                                    <div className="d-flex d-footer">
                                        <div className="logo-dxmb">
                                            <img src={require("../images/livestreams/logo-dxmb.png")} className={"logo"} />
                                        </div>
                                        <div className="emo-r">
                                            <div className="emo">
                                                <div className="icon-emo">
                                                    <a href=""><img src={require('../images/livestreams/emo1.png')} /></a>
                                                    <a href=""><img src={require("../images/livestreams/emo2.png")} /></a>
                                                    <a href=""><img src={require("../images/livestreams/emo3.png")} /></a>
                                                    <a href=""><img src={require("../images/livestreams/emo4.png")} /></a>
                                                    <a href=""><img src={require("../images/livestreams/emo5.png")} /></a>
                                                    <a href=""><img src={require("../images/livestreams/emo6.png")} /></a>
                                                    <a href=""><img src={require("../images/livestreams/emo7.png")} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-right">
                            <div className="comments">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#comment">Bình luận</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#member">Người tham dự <span
                                            className="number">135</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href=""><img src="images/window.png" /></a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div id="comment" className="tab-pane active"><br />

                                        <div className="ct-comment">
                                            <div className="even bg-cm">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even bg-cm">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even bg-cm">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even bg-cm">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="even bg-cm">
                                                <div className="d-flex flex-row">
                                                    <div className="avata mr-3">
                                                        <div className="img-avata"></div>
                                                    </div>
                                                    <div className="ct-cm">
                                                        <div className="user-time">Nguyễn Duy Mạnh &nbsp;
                                                            <span>15:30</span></div>
                                                        <div className="content-cm">Mình cbi vào ngành BĐS nè, mn chúc
                                                            mình thành công đi
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="write-cm">
                                            <div className="link pb-1">
                                                <a href=""><img src={require("../images/livestreams/link1.png")} /></a>
                                                <a href=""><img src={require("../images/livestreams/link2.png")} /></a>
                                                <a href=""><img src={require("../images/livestreams/link3.png")} /></a>
                                            </div>
                                            <input type="text" className="form-control-plaintext"
                                                placeholder="Bình luận của bạn..." name="text2" />
                                        </div>
                                    </div>
                                    <div id="member" className="container tab-pane fade"><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}